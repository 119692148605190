import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';

const columns = [
    { id: '1', label: 'Measure Description', minWidth: 50,span: false },
    { id: '2', label: 'Measure SN', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [unitId, setUnitid] = React.useState("");
    const [userId, setUserid] = React.useState("");
    const [unitName, setUnitname] = React.useState("");
    const [unitCode, setUnitcode] = React.useState("");

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {
      getData()
    }, []);
  
    const getData = () => {
      setLoader(true)
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      var data = {
        "type":  userdata.user_type,
        "companyid": company
      }
      fetch(global.url + "viewUnit", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            if(responseJson.status == "false") {
              setSource(responseJson.data)
              console.log("response", responseJson.data)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const editClick = (
      unitid, 
      unitname,
      unitcode,
      ) => {
        setModal(true)
        setUnitid(unitid)
        setUnitname(unitname)
        setUnitcode(unitcode)
    }

    const onAdd = () => {
        setModal(true)
        setUnitid("")
        setUnitname("")
        setUnitcode("")
    }

    const onSave = () => {
      var data = {
        "unit_id": unitId,
        "unit_name": unitName,
        "unit_code": unitCode,
        "unit_userid": userId,
        "unit_branchid" : userbranchid,
        "unit_companyid" : usercompanyid
      }
      console.log("data", data)
      fetch(global.url + "updateUnit", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("responsejson", responseJson);
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
          }
          if(responseJson.error == true) {
            alert(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const deleteClick = (id) =>  {
      var data= {
        "unit_id" : id
      }
      fetch(global.url + "deleteUnit", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert("Deleted")
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Unit listing</p>
               {
                      usertype == "superadmin" ? 
                      null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.unit_name}
                              </TableCell>
                              <TableCell >
                                  {row.unit_code}
                              </TableCell>
                              <TableCell >
                              {
                                  usertype == "superadmin" ? 
                                  null : 
                                  <a href="javascript:void(0)" onClick={() => 
                                      editClick(
                                        row.unit_id, 
                                        row.unit_name, 
                                        row.unit_code, 
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                      </a>
                                }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.unit_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                Unit Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={unitName} onChange={(e) => setUnitname(e.target.value)} id="outlined-basic" label="Unit code" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={unitCode} onChange={(e) => setUnitcode(e.target.value)} id="outlined-basic" label="unit name" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;