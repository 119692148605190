import React, {useState, useEffect} from 'react';
import { Row, Col } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

const columns = [
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '3', label: 'Bill No.', minWidth: 50,span: false },
    { id: '4', label: 'Counter', minWidth: 50,span: false },
    { id: '5', label: 'User', minWidth: 50,span: false },
    { id: '6', label: 'Net Total', minWidth: 50,span: false },
    { id: '7', label: 'Cash Paid', minWidth: 50,span: false },
    { id: '8', label: 'Bank Paid', minWidth: 50,span: false },
    { id: '9', label: 'Total paid', minWidth: 50,span: false },
  ];
 
  const rows = [
    {
      company: 'test',
    },
  ];

function PosSummery() {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbrachid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = React.useState('');

  const [source, setSource] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect (() => {
    getdata()
}, [])

  const getdata =() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "userid": userdata.user_id,
            "type": userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "posSalesReport", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error == false){
            setSource(responseJson.data)
          } 
          else{
            setSource([])
          }
            console.log("response possummary", responseJson)
        })
        .catch((error) => {
            console.log(error)
        })
    }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Pos Summary</p>
            </div>  
            <div style={{marginLeft: 20}}>
                {/* <Row>
                    <Col xs={12} md={3}>
                        <p style={{fontSize: 15, fontWeight: 600}}>From : 01 Jan 2021</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <p style={{fontSize: 15, fontWeight: 600}}>To : 30 Dec 2021</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <p style={{fontSize: 15, fontWeight: 600}}>Branch : All</p>
                    </Col>
                </Row> */}
            </div>        
            <TableContainer sx={{ maxHeight: 440, mt:2 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                           <TableCell>
                                  {row.date}
                              </TableCell>
                              <TableCell>
                                  {row.billno}
                              </TableCell>
                              <TableCell >
                                  {row.counter}
                              </TableCell>
                              <TableCell>
                                  {row.user}
                              </TableCell>
                              <TableCell>
                                  {row.nettotal}
                              </TableCell>
                              <TableCell >
                                  {row.cashpaid}
                              </TableCell>
                              <TableCell >
                                  {row.bankpaid}
                              </TableCell>
                              <TableCell>
                                  {row.totalpaid}
                              </TableCell>
                              <TableCell>
                                <a href={"/print/" + row.sales_id} target="_blank">
                                  <Button variant="contained">Print Bill</Button>
                                </a>
                              </TableCell>
                             
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
      </div>
    )
}
export default PosSummery;
