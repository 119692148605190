import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import WizardFormFirstPage from './firstpage';
import Fetchpage from './fetchpage';
import Defaultprice from './defaultprice';
import Loader from '../../loader/loader';
import BtnLoader from '../../loader/btnLoader';

const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Ref code', minWidth: 50,span: false },
    { id: '3', label: 'Name', minWidth: 50,span: false },
    { id: '4', label: 'PrintName', minWidth: 50,span: false },
    { id: '7', label: 'Othername', minWidth: 50,span: false },
    { id: '5', label: 'Brand', minWidth: 50,span: false },
    { id: '8', label: 'Category', minWidth: 50,span: false },
    { id: '10', label: 'Sub category', minWidth: 50,span: false },
    // { id: '11', label: 'Base unit', minWidth: 50,span: false },
    // { id: '12', label: 'Purchase unit', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

  const dates = [
      {
        company: 'Lorem Ipsum'
      },
      {
        company: 'Lorem Ipsum'
      }
  ]

function Comboproduct(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState('1');
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [form, setForm] = useState({
        productcode: "",
        productname: "",
        printname: "",
        othername: "",
        description: "",
        refcode: "",
        warranty: "",
        comboprice: "",
        stock: ""
      })
    const [count, setCount] = useState(1)
    const [bulkmodal, setBulkmodal] = React.useState(false);

    const [source, setSource] = React.useState([]);
    const [userId, setUserid] = React.useState('');
    const [productid, setProductid] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [batchbtnloader, setBatchbtnloader] = React.useState(false);
    const [unitbtnloader, setUnitbtnloader] = React.useState(false);
    const [batchlistloader, setBatchlistloader] = React.useState(false);
    const [unitlistloader, setUnitlistloader] = React.useState(false);

    const [errormessage, setErrormessage] = React.useState("");
    
    
    

    const [hsn, setHsn] = React.useState('');
    const [hsnList, setHsnlist] = React.useState([]);

    const [color, setColor] = React.useState("");
    const [size, setSize] = React.useState("");
    const [sizelist, setSizelist] = React.useState([]);
    const [colorlist, setColorlist] = React.useState([]);

    const [tax, setTax] = React.useState('');
    const [taxList, setTaxlist] = React.useState([]);

    const [itemGroup, setItemgroup] = React.useState('');
    const [itemgroupList, setItemgrouplist] = React.useState([]);

    const [brand, setBrand] = React.useState('');
    const [brandList, setBrandlist] = React.useState([]);

    const [branches, setBranches] = React.useState([]);

    const [category, setCategory] = React.useState('');
    const [categoryList, setCategorylist] = React.useState([]);

    const [subCategory, setSubcategory] = React.useState('');
    const [subcatlist, setSubcatlist] = React.useState([]);

    const [baseUnit, setBaseunit] = React.useState('');
    const [purchaseUnit, setPurchaseunit] = React.useState('');
    const [salesUnit, setSalesunit] = React.useState('');
    const [defaultPrice, setDefaultprice] = React.useState('');

    const [saveLoader, setSaveloader ] = React.useState(false)

    const [pothersid, setPotherid] = React.useState('');

    const [productSave, setProductsave] = React.useState(false);

    const [priceList, setPricelist] = React.useState([]);
    
    const [unitList, setUnitlist] = React.useState([]);

    const [selected, setSelected] = React.useState([]);
    const [branchlist, setBranchlist] = React.useState([]);
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])

  
    const [expiryDate, setExpirydate] = React.useState(new Date);
    const [producttype, setProducttype] = React.useState(0);
    const [file, setFile] = React.useState();
    const [bulkfile, setBulkfile] = React.useState();
    const [tableRow,setTableRow] = React.useState([{product_fetchproducts:"",pc_stock:"", product_name: "", product_code : "", color_name: "", size_name: "", pstock: ""}])
    
    const [code,setCode] = React.useState("")
    const hasResults = code.length > 0;
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [productlist, setProductlist] = React.useState([]);
    const [combolistloader, setCombolistloader] = React.useState(false);
    

    //Defaultprice
    const [dproductcolor, setDproductcolor] = React.useState("");
    const [dproductsize, setDproductsize] = React.useState("");
    const [dcost, setDcost] = React.useState("");
    const [dproductpurchaseprice, setDpurchaseprice] = React.useState("");
    const [dsalesrate, setDsalesrate] = React.useState("");
    const [dunit, setDunit] = React.useState("");
    const [dopeningqty, setDopeningqty] = React.useState("");
    const [dexpirydate, setDexpirydate] = React.useState(new Date);

    

    const radioChange = (event) => {
      console.log(event.target.value, "event.target.value")
      if(event.target.value == "nonbatch") {

        setProducttype(0)
      }
      else {
        setProducttype(1)
      }
    }

    const selectImage = () => {
      
    }

  
    const dfileSelectedHandler =(e) => {
      // setDproductimage(e.target.files)
    }



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  

  useEffect(() => {
    getData()
    getBranches()
    
  }, []);

  useEffect(() => {
    getData()
  }, []);


  const getSubcategorylist = () => {
   var userdata = JSON.parse(localStorage.getItem("user"))
   var company = JSON.parse(localStorage.getItem("company"))
   var data = {
    "type":  userdata.user_type,
    "companyid": company
  }
    fetch(global.url + "viewSubcategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setSubcatlist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const getColorlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewColor", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setColorlist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getSizelist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewSize", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setSizelist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getBranches = () => {
    
    fetch(global.url + "multipleBranch", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("branch", responseJson)
        if(responseJson.error == false){
            setBranchlist(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getHsnlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewHSN", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setHsnlist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getTaxlist = () =>{
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
      fetch(global.url + "viewTaxmaster", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false"){
          console.log("response", responseJson.data)
          setTaxlist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getBrandlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewBrand", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("brand response", responseJson)
          if(responseJson.status == "false") {
            setBrandlist(responseJson.data)
            console.log("response", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getItem = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewItemgroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("brand response", responseJson)
          if(responseJson.status == "false") {
            setItemgrouplist(responseJson.data)
            console.log("response", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getUnitlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewUnit", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.status == "false") {
            setUnitlist(responseJson.data)
            console.log("response", responseJson.data)
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const getPricelist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewPricelevel", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          if(responseJson.status == "false") {
            setPricelist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCategorylist = () => {
    
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.status == "false") {
            setCategorylist(responseJson.data)
            console.log("response", responseJson.data)
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)
   
    var data = {
      "usertype": userdata.user_type,
      "companyid": company,
      "branchid" : branch
    }
    fetch(global.url + "ViewFetchCombo", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        //   console.log("response", responseJson)
          setLoader(false)
          if(responseJson.error == false) {
            setSource(responseJson.data)
            // setTableRow(responseJson.data.products)
            console.log("response", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const hsnChange = (event) => {
      setHsn(event.target.value);
    };

    const taxChange = (event) => {
      setTax(event.target.value);
    };

    const itemgroupChange = (event) => {
      setItemgroup(event.target.value);
    };

    const brandChange = (event) => {
      setBrand(event.target.value);
    };


    const categoryChange = (event) => {
      setCategory(event.target.value);
    };

    const subcategoryChange = (event) => {
      setSubcategory(event.target.value);
    };

    const baseunitChange = (event) => {
      setBaseunit(event.target.value);
    };


    const purchaseunitChange = (event) => {
      setPurchaseunit(event.target.value);
    };


    const salesunitChange = (event) => {
      setSalesunit(event.target.value);
    };

    const defaultpriceChange = (event) => {
      setDefaultprice(event.target.value);
    };

    const dproductcolorChange = (event) => {
      setDproductcolor(event.target.value);
    };

    const dproductsizechange = (event) => {
      setDproductsize(event.target.value);
    };

    const dunitchange = (event) => {
      setDunit(event.target.value);
    };

    

    

  const onNext = () => {
  
      // setSaveloader(true)

      const exampleArray = []
        for(var i = 0 ; i< tableRow.length; i++) {
            if(tableRow[i].product_fetchproducts == "") {
                // tableRow.splice(i, 1)
            }
            else{
                exampleArray.push(tableRow[i])
            } 
        }
        var data = {
          "products_id": productid,
          "product_userid": userId,
          "product_branchid": userbranchid,
          "product_companyid": usercompanyid,
          "product_code": form.productcode,
          "product_type": producttype,
          "product_name": form.productname,
          "product_printname": form.printname,
          "product_othername": form.othername,
          "product_warranty": form.warranty,
          "product_refcode": form.refcode,
          "product_hsn": hsn,
          "product_tax": tax,
          "product_description": form.description,
          "product_itemgroup": itemGroup,
          "product_brand": brand,
          "product_category": category,
          "product_subcategory": subCategory,
          "product_baseunit": baseUnit,
          "product_saleunit": salesUnit,
          "product_purchaseunit": purchaseUnit,
          "product_defaultprice": defaultPrice,
          "product_branches": selected,
          "combo_table" : exampleArray,
          "product_comboprice": form.comboprice,
          "product_iscombo": 1,
          "product_combostock": form.stock
        }
        console.log("Productfetch data", data)

        fetch(global.url + "editproductFetchCombo", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("responsejson save", responseJson);
            if(responseJson.error == false) {
              setProductid(responseJson.data) 
              alert(responseJson.message)
              setSaveloader(false)
              onhide()
            }
            if(responseJson.status == "true") {
              alert(responseJson.message)
              setProductsave(false)
              setSaveloader(false)
            }
            if(responseJson.status == "false") {
              alert(responseJson.message)
              setSaveloader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const editClick = (
    productid, 
    productcode,
    productname,
    printname,
    othername,
    hsn,
    tax,
    description,
    itemgroup,
    brand,
    category,
    subcategory,
    baseunit,
    purchaseunit,
    salesunit,
    defaultprice,
    branches,
    producttype,
    refcode,
    warranty,
    comboprice,
    stock,
    tablerow
  ) => {
    console.log("productid editclick", comboprice)
    getSelectedbranch(productid)
    getHsnlist()
    getItem()
    getBrandlist()
    getCategorylist()
    getUnitlist()
    getPricelist()
    getTaxlist()
    getSubcategorylist()
    getColorlist()
    getSizelist()

    setModal(true)
    setEditmode(true)
    setProductid(productid)
    const newValues = { ...form }
    newValues['productcode']= productcode
    newValues['productname']= productname
    newValues['printname']= printname
    newValues['othername']= othername
    newValues['description']= description
    newValues['refcode']= refcode
    newValues['warranty']= warranty
    newValues['comboprice']= comboprice
    newValues['stock']= stock
    setForm(newValues)
    setHsn(hsn)
    setTax(tax)
    setItemgroup(itemgroup)
    setBrand(brand)
    setCategory(category)
    setSubcategory(subcategory)
    setBaseunit(baseunit)
    setPurchaseunit(purchaseunit)
    setSalesunit(salesunit)
    setDefaultprice(defaultprice)
    setProducttype(producttype)
    viewCombodetails(productid)
    setTableRow(tablerow)

    setExpirydate(new Date)
    setPotherid("")
    setProductsave(false)
    
  }

  const viewCombodetails = (id) => {
        // setCombolistloader(true)
        // var data = {
        //   "product_id": id,
        // }
        // fetch(global.url + "viewproductCombo", {
        //   method: "POST",
        //   headers: {
        //       'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(data),
        // })
        // .then((response) => response.json())
        // .then((responseJson) => {
        //     console.log("combo table", responseJson)
        //     setCombolistloader(false)
        //     if(responseJson.error == false){
        //       setTableRow(responseJson.data)
        //     }
           
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
  }


  const getSelectedbranch =(id) => {
        var data = {
          "id": id,
          "type": "product"
        }
        fetch(global.url + "multipleBranchSelect", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error == false) {
            setSelected(responseJson.data)
          }
        })
        .catch((error) => {
            console.log(error)
        })
  }

  const onAdd = () => {
    getHsnlist()
    getItem()
    getBrandlist()
    getCategorylist()
    getUnitlist()
    getPricelist()
    getTaxlist()
    getSubcategorylist()
    getColorlist()
    getSizelist()
    
    setModal(true)
    setEditmode(false)
    setProductid("")
    const newValues = { ...form }
    newValues['productcode']= ""
    newValues['productname']= ""
    newValues['printname']= ""
    newValues['othername']= ""
    newValues['description']= ""
    newValues['refcode']= ""
    newValues['warranty']= ""
    newValues['comboprice']= ""
    newValues['stock']= ""
    setForm(newValues)
    setHsn("")
    setTax("tax")
    setItemgroup("")
    setBrand("")
    setCategory("")
    setSubcategory("")
    setBaseunit("")
    setPurchaseunit("")
    setSalesunit("")
    setDefaultprice("")
    setProducttype(0)
    setSelected([])
    setTableRow([{product_fetchproducts:"",product_quantity:"", product_name: "", product_code : "", color_name: "", size_name: "", pstock: ""}])
    getProductcode()
    setExpirydate(new Date)
    setProductsave(false)
  }

  const getProductcode = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var data = {
      "userid":  userdata.user_id
    }
    fetch(global.url + "getProductcode", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
        const newValues = { ...form }
        newValues['productcode']= responseJson.data.productcode
        newValues['productname']= ""
        newValues['printname']= ""
        newValues['othername']= ""
        newValues['description']= ""
        newValues['refcode']= ""
        newValues['warranty']= ""
        setForm(newValues)
      })
      .catch((error)=>{
          console.log(error)
      })
}

  const getSearch = (text, type) => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    if(type = "code") {
        var data = {
            "type" : type,
            "product_code":  text,
            "companyid" : company,
            "branchid" : branch
        }
    }
    else {
        var data = {
            "type" : type,
            "product_name":  text,
            "companyid" : company,
            "branchid" : branch
        }
    }
    
    fetch(global.url + "getProductbySearch", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("response", responseJson)
        setProductlist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getSearchfetch = (text) => {
  var branch = JSON.parse(localStorage.getItem("branch"))
  var company = JSON.parse(localStorage.getItem("company"))
  var data = {
      "type":  "refcode",
      "refcode":  text,
      "companyid" : company,
      "branchid" : branch
  }
  console.log("var data", data)
  fetch(global.url + "getSearchStock", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      console.log("response fetch", responseJson)
      if(responseJson.error == false) {
        setProductlist(responseJson.data)
      }
      
  })
  .catch((error)=>{
      console.log(error)
  })
}


 

  const onhide = () => {
    setEditmode(false)
    setModal(false)
    getData()
  }

  const updateForm = (e) => {
    setForm({
    ...form,
    [e.target.name]: e.target.value,
    })
  }

  const deleteClick = (id) =>  {
    var data = {
      "products_id": id,
    }
    fetch(global.url + "deleteProducts", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  // const listClickcode = (index, batchid, code, name, stock, color, size) => {
  //     var input = [...tableRow]
  //     input[index].product_code = code;
  //     input[index].pc_batch_id_fk = batchid;
  //     input[index].pc_stock = stock;
  //     input[index].pstock = stock;
  //     input[index].product_name = name;
  //     input[index].color_name = color;
  //     input[index].size_name = size;
  //     setTableRow(input)
  //     setCode("")
  //     setTableRow([...tableRow, {pc_batch_id_fk:"",pc_stock:"", product_name: "", product_code : "", color_name: "", size_name: "",pstock: ""}])
  // }

  const listClickcode = (index, batchid, code, name, refcode) => {
    var input = [...tableRow]
    input[index].product_code = refcode;
    input[index].product_fetchproducts = batchid;
    
    input[index].product_name = name;
    
    setTableRow(input)
    setCode("")
    setTableRow([...tableRow, {product_fetchproducts:"",pc_stock:"", product_name: "", product_code : "", color_name: "", size_name: "",pstock: ""}])
}

  // const handleOnChange = (event, key, type) => {
  //     var textArray1 = [...tableRow]
  //     textArray1[key].product_code = event.currentTarget.value;
  //     setTextarray([...tableRow, textArray1])
  //     setSelectedindex(key)
  //     console.log("log1", event.currentTarget.value)
  //     setCode(event.currentTarget.value);
  //     getSearch(event.currentTarget.value, type)
  // }

  const handleOnChange = (event, key, type) => {
    var textArray1 = [...tableRow]
    textArray1[key].product_code = event.currentTarget.value;
    setTextarray(textArray1)
    setSelectedindex(key)
    setCode(event.currentTarget.value);
    getSearchfetch(event.currentTarget.value)
}

  const stockChange = (event, key, type) => {
    var textArray1 = [...tableRow]
    textArray1[key].product_quantity = event.currentTarget.value;
    setTextarray([...tableRow, textArray1])
    setSelectedindex(key)
    setErrormessage("")
  }

  const addtablerow = () => {
    setTableRow([...tableRow, {product_fetchproducts:"",product_quantity:"", product_name: "", product_code : "", color_name: "", size_name: "",pstock: ""}])
  }

//   const deleteCombo = (id) => {
//         var data = {
//           "pc_id": id,
//         }
//         fetch(global.url + "deleteCombo", {
//             method: "POST",
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(data),
//         })
//         .then((response)=> response.json())
//         .then((responseJson)=>{
//             console.log("responsejson", responseJson)
//             alert(responseJson.message)
//             viewCombodetails(productid)
//         })
//         .catch((error)=>{
//             console.log(error)
//         })
//   }

        const deleteCombo = (index) => {
            var input = [...tableRow]
            input.splice(index, 1)
            console.log("input", input)
            setTableRow(input)
        }

    const searchProduct = (text) => {
      
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
     
      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "refcode": text,
        "branchid" : branch
      }
      // console.log("ViewFetchCombo data", data)
      fetch(global.url + "ViewFetchCombo", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setLoader(false)
            if(responseJson.error == false) {
              setSource(responseJson.data)
              // setTableRow(responseJson.data.products)
              console.log("response", responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    return(
        <div className="main-content">
          {modal == false ? 
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Product Combo List</p>
               <FormControl sx={{ width: '20%' }}>
                    <TextField name="Zone"
                    onChange={(e) => searchProduct(e.target.value)}
                    autoComplete="off" id="outlined-basic" label="Referal code Search" variant="outlined"  size="small"/>
                </FormControl>
               <div>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button variant="contained"  onClick={onAdd}>Add</Button>
               }
               </div>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.product_code}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {row.product_refcode}
                              </TableCell>
                              <TableCell >
                                  {row.product_name}
                              </TableCell>
                              <TableCell >
                                  {row.product_printname}
                              </TableCell>
                              <TableCell >
                                  {row.product_othername}
                              </TableCell>
                              <TableCell >
                                  {row.brand_name}
                              </TableCell>
                              <TableCell >
                                  {row.category_name}
                              </TableCell>
                              <TableCell >
                                  {row.subCategory_name}
                              </TableCell>
                              <TableCell >
                              {
                                  usertype == "superadmin" ? 
                                  null : 
                                <a href="javascript:void(0)" onClick={() => 
                                    editClick(
                                        row.products_id, 
                                        row.product_code, 
                                        row.product_name, 
                                        row.product_printname, 
                                        row.product_othername,
                                        row.product_hsn, 
                                        row.product_tax,
                                        row.product_description,
                                        row.product_itemgroup,
                                        row.product_brand,
                                        row.product_category,
                                        row.product_subcategory,
                                        row.product_baseunit,
                                        row.product_purchaseunit,
                                        row.product_saleunit,
                                        row.product_defaultprice,
                                        row.product_branches,
                                        row.product_type,
                                        row.product_refcode,
                                        row.product_warranty,
                                        row.product_comboprice,
                                        row.product_combostock,
                                        row.products
                                    )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.products_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          :
          <Row style={{background: '#fff'}}>
            <Col xs={12}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Product Combo</p>
               <Button variant="contained"  onClick={onhide}>Back</Button>
            </div>
                {/* <WizardFormFirstPage 
                      form={form} 
                      updateform = {updateForm}
                      hsn = {hsn}
                      hsnList = {hsnList}
                      tax = {tax}
                      taxList = {taxList}
                      itemGroup = {itemGroup}
                      itemgroupList = {itemgroupList}
                      brand = {brand}
                      brandList = {brandList}
                      category = {category}
                      categoryList = {categoryList}
                      subCategory = {subCategory}
                      subcatlist = {subcatlist}
                      baseUnit = {baseUnit}
                      purchaseUnit = {purchaseUnit}
                      salesUnit = {salesUnit}
                      defaultPrice = {defaultPrice}
                      priceList = {priceList}
                      unitList = {unitList}
                      hsnChange = {hsnChange}
                      taxChange = {taxChange}
                      itemgroupChange = {itemgroupChange}
                      brandChange = {brandChange}
                      categoryChange = {categoryChange}
                      subcategoryChange = {subcategoryChange}
                      purchaseunitChange = {purchaseunitChange}
                      salesunitChange = {salesunitChange}
                      defaultpriceChange = {defaultpriceChange}
                      baseunitChange = {baseunitChange}

                      producttype = {producttype}
                      radioChange = {radioChange}
                      editmode = {editMode}

                      selected = {selected}
                      setSelected = {setSelected}
                      branchlist = {branchlist}
                      selectImage = {selectImage}

                      color = {color}
                      colorlist = {colorlist}
                  

                      size = {size}
                      sizelist = {sizelist}                     

                      expirydate = {expiryDate}
                      productid = {productid}
                      tableRow = {tableRow}

                      handleOnChange={handleOnChange}
                      hasResults={hasResults}
                      selectedIndex={selectedIndex}
                      productlist={productlist}
                      listClickcode={listClickcode}
                      stockChange={stockChange}
                      errormessage ={errormessage}

                      dproductcolor = {dproductcolor}
                      dproductcolorChange={dproductcolorChange}
                      dproductsize={dproductsize}
                      dproductsizechange={dproductsizechange}
                      dcost={dcost}
                      setDcost={setDcost}
                      dproductpurchaseprice={dproductpurchaseprice}
                      setDpurchaseprice={setDpurchaseprice}
                      dsalesrate={dsalesrate}
                      setDsalesrate={setDsalesrate}
                      dunit={dunit}
                      dunitchange={dunitchange}
                      dopeningqty={dopeningqty}
                      setDopeningqty={setDopeningqty}
                      unitList={unitList}
                      sizelist={sizelist}
                      colorlist={colorlist}
                      dexpirydate={dexpirydate}
                      setDexpirydate={setDexpirydate}
                      dfileSelectedHandler={dfileSelectedHandler}
                      combolistloader={combolistloader}
                      deleteCombo={deleteCombo}
                      addtablerow={addtablerow}
                /> */}
                <Fetchpage 
                      form={form} 
                      updateform = {updateForm}
                      hsn = {hsn}
                      hsnList = {hsnList}
                      tax = {tax}
                      taxList = {taxList}
                      itemGroup = {itemGroup}
                      itemgroupList = {itemgroupList}
                      brand = {brand}
                      brandList = {brandList}
                      category = {category}
                      categoryList = {categoryList}
                      subCategory = {subCategory}
                      subcatlist = {subcatlist}
                      baseUnit = {baseUnit}
                      purchaseUnit = {purchaseUnit}
                      salesUnit = {salesUnit}
                      defaultPrice = {defaultPrice}
                      priceList = {priceList}
                      unitList = {unitList}
                      hsnChange = {hsnChange}
                      taxChange = {taxChange}
                      itemgroupChange = {itemgroupChange}
                      brandChange = {brandChange}
                      categoryChange = {categoryChange}
                      subcategoryChange = {subcategoryChange}
                      purchaseunitChange = {purchaseunitChange}
                      salesunitChange = {salesunitChange}
                      defaultpriceChange = {defaultpriceChange}
                      baseunitChange = {baseunitChange}

                      producttype = {producttype}
                      radioChange = {radioChange}
                      editmode = {editMode}

                      selected = {selected}
                      setSelected = {setSelected}
                      branchlist = {branchlist}
                      selectImage = {selectImage}

                      color = {color}
                      colorlist = {colorlist}
                  

                      size = {size}
                      sizelist = {sizelist}                     

                      expirydate = {expiryDate}
                      productid = {productid}
                      tableRow = {tableRow}

                      handleOnChange={handleOnChange}
                      hasResults={hasResults}
                      selectedIndex={selectedIndex}
                      productlist={productlist}
                      listClickcode={listClickcode}
                      stockChange={stockChange}
                      errormessage ={errormessage}

                      dproductcolor = {dproductcolor}
                      dproductcolorChange={dproductcolorChange}
                      dproductsize={dproductsize}
                      dproductsizechange={dproductsizechange}
                      dcost={dcost}
                      setDcost={setDcost}
                      dproductpurchaseprice={dproductpurchaseprice}
                      setDpurchaseprice={setDpurchaseprice}
                      dsalesrate={dsalesrate}
                      setDsalesrate={setDsalesrate}
                      dunit={dunit}
                      dunitchange={dunitchange}
                      dopeningqty={dopeningqty}
                      setDopeningqty={setDopeningqty}
                      unitList={unitList}
                      sizelist={sizelist}
                      colorlist={colorlist}
                      dexpirydate={dexpirydate}
                      setDexpirydate={setDexpirydate}
                      dfileSelectedHandler={dfileSelectedHandler}
                      combolistloader={combolistloader}
                      deleteCombo={deleteCombo}
                      addtablerow={addtablerow}
                />
                <Row style={{marginTop: 20}}>
                  <Col xs={12} >
                    {saveLoader ? 
                    <div style={{float: 'right'}}>
                        <BtnLoader />
                    </div>
                  :
                    <Button onClick={onNext} style={{float: 'right', marginBottom: 20}}  variant="contained">Product Combo Save</Button>
                  }
                  </Col>
                </Row>
            
              </Col>
              
          </Row>
          }
      </div>
    )
}
export default Comboproduct;