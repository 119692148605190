
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";


const columns = [
    { id: '2', label: 'Date', minWidth: 50,span: false },
    { id: '3', label: 'Bill No.', minWidth: 50,span: false },
    { id: '4', label: 'Supplier', minWidth: 50,span: false },
    // { id: '5', label: 'Discount', minWidth: 50,span: false },
    { id: '7', label: 'Net Total', minWidth: 50,span: false },
    { id: '8', label: 'File', minWidth: 50,span: false },
    { id: '9', label: 'Action', minWidth: 50,span: true },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Purchasereport(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    
    const [value, setValue] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [age, setAge] = React.useState('');
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [modal, setModal] = React.useState(false);
    const [billDetails, setBillsetails] = React.useState([]);
    const [supplierList, setSupplierList] = React.useState([]);
    const [date1, setDate1] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [supplier, setSupplier] = React.useState("");
    

  const SelectChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      getData()
      getSupplier()
  }, [])

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
        "type":  "purchase",
        "usertype":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
      }
    fetch(global.url + "viewPurchasehead", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response12", responseJson)
      setLoader(false)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
      }
    })
  }
  const filterDate = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var branch = JSON.parse(localStorage.getItem("branch"))
      var data = {
        "type":  "purchase",
        "usertype":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "from_date" : date1.toISOString().split('T')[0],
        "to_date" : date2.toISOString().split('T')[0],
        "supplier": supplier
      }
      // console.log('1324' , data)
    fetch(global.url + "viewPurchasehead", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log('date' , responseJson)
      setLoader(false)
      if(responseJson.status == "false"){
        setSource(responseJson.data)
      }
    })
  }

  const getSupplier = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewSupplier", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log('sup' , responseJson)
      setSupplierList(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
  }
  const SupplierChange = (e) =>{
    setSupplier(e.target.value);
}
  const viewBillclick = (id) => {
    // setModal(true)

    // var data = {
    //   "purchase_id":  id
    // }
    // fetch(global.url + "viewPurchaseDetail", {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type' : 'application/json'
    //     },
    //     body: JSON.stringify(data)
    // })
    // .then((response) => response.json())
    // .then((responseJson) => {
    //   console.log("report sale detail", responseJson)
    //   if(responseJson.status == "false"){
    //     setBillsetails(responseJson.data)
    //   }
    //   else{
    //     setBillsetails([])
    //   }
       
    // })
    const win = window.open('/purchaseinvoice/'+ id, "_blank");
    win.focus();
  }

  const returnClick = (id , billno) => {
    if (window.confirm('Are you sure you want to return the Purchase bill ' + billno + " ?")) {
      var data = {
        "purchase_id":  id,
        "companyid": usercompanyid,
        "branchid": userbranchid,
        "user_id": userId
      }
      console.log("data", data)
      fetch(global.url + "purchaseReturn", {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.error == false){
          getData()
          alert(responseJson.message)
        }
          console.log("response", responseJson)
      })
    } else {
      // Do nothing!
      console.log('Thing');
    }
  }

  const productReturnClick = (id, productname, salesid) => {
    if (window.confirm('Are you sure you want to return the Product ' + productname + " ?")) {
      var data = {
        "pd_id":  id,
        "branchid": userbranchid,
        "companyid": usercompanyid,
        "user_id": userId
      }
      console.log("data", data)
      fetch(global.url + "purchaseReturnEach", {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.error == false){
          viewBillclick(salesid)
          alert(responseJson.message)
        }
          console.log("response", responseJson)
      })
    } else {
      // Do nothing!
      console.log('Thing');
    }
}

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Purchase Report</p>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From"
                                value={date1}
                                onChange={(newValue) => {
                                setDate1(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To"
                                value={date2}
                                onChange={(newValue) => {
                                setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    {/* <Col xs={12} md={6} lg={3} style={{marginTop: 20}}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Branch"
                                    onChange={SelectChange}
                                    size="small"
                                >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Col> */}
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={supplier}
                                    label="Branch"
                                    onChange={SupplierChange}
                                    size="small"
                                >
                                <MenuItem value=''>none</MenuItem>
                                {supplierList.map((row, index) => {
                                      return (
                                <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                               
                                )})}
                                </Select>
                            </FormControl>
                        </Box>
                    </Col>
                    <Col xs={12} md={6} lg={3} style={{marginTop: 20}}>
                        <Button onClick={filterDate} variant="primary">Filter</Button>
                    </Col>
                </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >
                              
                              <TableCell >
                                  {row.purchase_date}
                              </TableCell>
                              <TableCell >
                                  {row.purchase_invoice}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_name}
                              </TableCell>
                              {/* <TableCell >
                                  {row.purchase_discount}
                              </TableCell> */}
                              <TableCell >
                                  {row.purchase_total}
                              </TableCell>
                              <TableCell >
                                <Button variant="contained">View</Button>
                              </TableCell>
                              <TableCell>
                                  <Button onClick={() => returnClick(row.purchase_id, row.purchase_invoice)}  variant="primary" style={{padding: 5, fontSize: 10}}>Purchase Return</Button>
                              </TableCell>
                              <TableCell>
                                  <Button onClick={() => viewBillclick(row.purchase_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button>
                              </TableCell>
                              <TableCell >
                                  <a href={'/transaction/Purchase/' + row.purchase_invoice} >
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Purchase Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>discount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.code}</td>
                                <td>{item.product}</td>
                                <td>{item.qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.discount}</td>
                                <td>{item.amount}</td>
                                <td><Button onClick={() => productReturnClick(item.pd_id, item.product, item.sales_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Return Product</Button></td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
      </div>
    )
}
export default Purchasereport;